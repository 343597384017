import React from "react";
import "./Hero.css";
 
const HeroSection = () => {
  return (
    <div className="hero-container" id="home">
      <video className="hero-video" playsInline autoPlay loop muted>
        <source 
          src="https://res.cloudinary.com/dv2w3pig9/video/upload/v1734786212/tcwhgy03wci91qjtgwov.mp4" 
          type="video/mp4" 
        />
        Your browser does not support the video tag.
      </video>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1 className="hero-title">
          Creation Is An Addiction
        </h1>
        <p className="tagline"></p>
      </div>
    </div>
  );
};
 
export default HeroSection;
 